import React, { useRef, useEffect } from 'react';
import Grow from '@material-ui/core/Grow';
import { graphql, useStaticQuery, Link } from "gatsby"
import gsap from "gsap";

import Img from "gatsby-image"
import styled from "styled-components"


import budapest from '../images/budapest-at-night.jpg'
import hungaryTaxRebate from '../images/hungary-tax-rebate.png'

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 55px;
    width: 100vw;
    height: 100vh;
    // background-image: url(${budapest});
    // background-repeat: no-repeat;
    // background-position: center;
    
    
    &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    
    &::after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%2321409a' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E")
    }
    
`

const ContentWrapper = styled.div`
    position: relative;
    margin-top: 150px;
    @media screen and (max-width: 1025px) {
        margin-top: 40vh;
    }
  
`

const ContentPanel = styled.div`
    position: relative;
    max-width: 1280px;
    min-height: inherit;
    width: 61.8%;
    width: 65%;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 85px;
    
    // background: rgba(255,255,255,0.8);

    @media screen and (max-width: 1025px) {
        margin-left: 0;
        height: auto;
        width: auto;
    }
   
`

const ContentInner = styled.div`
    padding: 32px;

    @media screen and (max-width: 1025px) {
        padding: 0;
        width: 100%;
    }
    
    h1, h2, p {
        font-family: 'futura-pt', sans-serif;
        padding: 16px 32px;
        margin-bottom: 0;
        overflow: hidden;
        span {
            display: inline-block;
        }
    }

    h1 {
        @media screen and (max-width: 1025px) {
            font-size: 28px;
        }

    }

    h2 {
        @media screen and (max-width: 1025px) {
            font-size: 24px;
        }
        
    }
    
    p {
        font-size: 24px;
        line-height: 1.4;

        
        @media screen and (max-width: 1025px) {
            font-size: 18px;
        }
    }
    
    img {
        margin-bottom: 0;
    }
    
    .inner-group {
        margin-bottom: 4px;
        background: rgba(255, 217, 1, 0.9);
        background: rgba(12,12,12,0.85);
        color: rgba(255,217,1,0.9);
       
    }
    
    .inner-divider {
        margin-top: 32px;
    }
    
    .extra-padding {
        padding-bottom: 16px;
    }
    
    .image-wrapper {
        width: 100%;
        display: flex;
        // justify-content: center;
        padding: 12px;
    }
    
    
    a {
        color: rgba(12,12,12,0.8);
        color: rgba(255,255,255,0.7);
        text-decoration: none;
        
        &:hover {
            color: black;
            color: rgba(255,255,255,1);
        }
    }
`

const StyledImg = styled(Img)`
  @media screen and (max-width: 1025px) {
    height: 100vh;
    width: auto;
  }
   
`

const ShootingInBudapestContent = () => {
    let content = useRef(null);
    let content2 = useRef(null);
    let content3 = useRef(null);
    let text1 = useRef(null);
    let text2 = useRef(null);
    let text3 = useRef(null);
    
    useEffect(()=>{
        gsap.from([content.current, content2.current, text1.current, text2.current, text3.current, content3.current], {
            y: 100,
            // opacity: 0, 
            duration: 1,
            delay: 0.5,
            stagger: 0.3,
        })
        
    }, [])
    
    const data = useStaticQuery(graphql`
        query {
          file(relativePath: {eq: "budapest-at-night2.jpg"}) {
            childImageSharp {
              # Specify the image processing specifications right in the query.
              # Makes it trivial to update as your page's design changes.
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
    `)
    
    return (
        <React.Fragment>
        <Container>
            <StyledImg
                fluid={data.file.childImageSharp.fluid} 
                alt="Budapest at night"
            />
        </Container>
        
        <ContentWrapper>
            <ContentPanel>
                <ContentInner>
                    <Grow in={true} {...(true ? { timeout: 1000 } : {})}>
                        <div className="inner-group">
                            <h1><span ref={content}>Shooting in Hungary</span></h1>
                        </div>
                    </Grow>
                    <Grow in={true} {...(true ? { timeout: 2000 } : {})}>
                        <div className="inner-group">
                            <h2><span ref={content2}>Hungarian Tax Rebate for Film Productions</span></h2>
                        </div>
                    </Grow>
                    <Grow in={true} {...(true ? { timeout: 3000 } : {})}>
                    <div className="inner-group extra-padding" >
               
                        <p>Initiated by the Hungarian National Film Fund, the European Commission has approved the raise of Hungary’s tax incentive to 30%. The tax incentive is available for film productions shooting in Hungary. The film support scheme is extended until the end of 2024.</p>
                        <p>The scheme is part of the film support programme approved by the European Commission until 31st of December 2019 with a budget of EUR 402 million. The incentive is available through local business companies who receive tax relief after their support of films or through the Collection Account operated by the Hungarian National Film Fund. The incentive is guaranteed by the Hungarian state through the Collection Account. The financial support is provided in the form of a cash refund (post-financing).The incentive is up to 30% of the eligible production expense (Hungarian or not-Hungarian). The non-Hungarian eligible spend is capped at 30% of the Hungarian eligible spend.</p>
                        <div className="image-wrapper">
                            <img src={hungaryTaxRebate} alt="Hungarian tax rebate" />
                        </div>
                        <p>See the detailed presentation on the <a href="https://nfi.hu/en/industry/hungarian-tax-rebate-for-film-productions" target="_blank">National Film Institute Hungary's website.</a></p>
                 
                    </div>
                    </Grow>
              
                    <div className="inner-group inner-divider">
                        <h2><span ref={content3}>About Budapest</span></h2>
                    </div>
         
            
                    <div className="inner-group">
                        <p>Budapest is one of the most progressive cities in Central Europe and comparisons can be drawn to Paris and Prague in both style and architecture.</p>
                        <p>The city sprawls from the majestic curve of the Danube with Pest on the right the more busy, modern side where the majority of the nightlife can be found. On the other side Buda with the stunning Castle Hill and the Buda Hills. From the beautiful bath houses to an array of nightlife there is something for everyone in Budapest.</p>
                        <p>Budapest has a temperate continental climate. Seasons are usually well defined, with July and August the hottest months (28-30° C, 82-86° F) and December and January the coldest, when temperatures may fall under -15° C or just +5° F. Average sunshine from April to September is more than eight hours a day.</p>
                    </div>
         
                </ContentInner>
            </ContentPanel>
        </ContentWrapper>
        </React.Fragment>
    )
}

export default ShootingInBudapestContent