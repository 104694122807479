import React from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/shooting-in-budapest-content"


const ShootingInBudapestPage = () => {
    
    return (
        <Layout>
            <SEO title="Shooting in Budapest" />
            <Content />
        </Layout>
    )
}

export default ShootingInBudapestPage;